@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Young+Serif:wght@400&family=Bitter:wght@400;700&display=swap');

/* Global Styles */
body {
  @apply bg-white text-black font-paragraph;
}

/* Headings Styles */
h1, h2, h3, h4, h5, h6 {
  @apply font-heading;
}

/* Custom Background Classes */
.bg-beige {
  background-color: #E7D1B5; /* Custom beige color */
}

.circular-gradient {
  background: radial-gradient(circle, #f1a638, #e7d1b5);
}

html {
  scroll-behavior: smooth;
}