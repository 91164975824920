
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #fff;
}

.header {
  height: 100vh;
  background: url("images/HaloHero.jpg") center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header h1 {
  font-size: 3rem;
  color: #ffd700;
}

.header p {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.buttons a {
  padding: 0.75rem 1.5rem;
  color: #fff;
  background: #ffd700;
  margin: 0 0.5rem;
  border-radius: 5px;
  text-decoration: none;
}

.services-section {
  text-align: center;
  padding: 4rem 1rem;
}

.services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.service {
  position: relative;
  padding: 2rem;
  background: #1f1f1f;
  border-radius: 10px;
  transition: transform 0.3s;
}

.service:hover {
  transform: translateY(-10px);
}

.service .hover-detail {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 5px;
  width: 90%;
  color: #ffd700;
}

.service:hover .hover-detail {
  opacity: 1;
}

.consultation-section {
  background: #000;
  text-align: center;
  padding: 4rem 1rem;
}

.consultation-form input,
.consultation-form textarea,
.consultation-form button {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: none;
}

.consultation-form button {
  background: #ffd700;
  color: #000;
}

.footer {
  background: #000;
  text-align: center;
  padding: 2rem 1rem;
  color: #bbb;
}

.footer a {
  color: #ffd700;
  text-decoration: none;
}


h1, h2, h3, .logo {
  font-family: 'Playfair Display', serif;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
